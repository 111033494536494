import {
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUpdateClinicMessagingServiceSidByAdminMutation } from "src/graphql/generated";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateClinicMessagingServiceSidByAdminSchema } from "src/validations/updateClinicMessagingServiceSidByAdminSchema";
import { FIND_AND_COUNT_MANY_CLINICS_BY_ADMIN } from "src/graphql/queries/findAndCountManyClinicsByAdmin.gql";
import { PATHS } from "src/constants";
import { toast } from "react-toastify";

interface FormValues {
  id: string;
  messagingServiceSid: string;
}

function UpdateMessagingServiceSidPage() {
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const id = query.get("id") as string;
  const messagingServiceSid = query.get("sid") as string;

  const [updateSidMutation, { loading }] =
    useUpdateClinicMessagingServiceSidByAdminMutation();

  const methods = useForm<FormValues>({
    resolver: yupResolver(updateClinicMessagingServiceSidByAdminSchema),
    defaultValues: {
      id,
      messagingServiceSid,
    },
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = methods;

  const onSubmit = (formValues: FormValues) => {
    if (messagingServiceSid !== formValues.messagingServiceSid)
      updateSidMutation({
        variables: { schema: { ...formValues } },
        refetchQueries: [FIND_AND_COUNT_MANY_CLINICS_BY_ADMIN],
        onCompleted: (data) => {
          if (data) toast.success("Successful");
        },
        onError: (error) => {
          if (error) toast.error(error.message);
        },
      });
    navigate(PATHS.clinics);
  };

  return (
    <Box pb="24px">
      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Card sx={{ width: "50%" }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid container spacing={3} xs={12} item>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="messaging-sid">
                      Messaging service sid
                    </InputLabel>
                    <OutlinedInput
                      id="messaging-sid"
                      type="text"
                      fullWidth
                      autoComplete="messaging-sid"
                      error={Boolean(errors.messagingServiceSid)}
                      {...register("messagingServiceSid")}
                    />
                    {errors.messagingServiceSid && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-messaging-sid"
                      >
                        {errors?.messagingServiceSid?.message?.toString()}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Button
            disabled={loading}
            type="submit"
            sx={{ marginTop: "24px" }}
            variant="contained"
            startIcon={<AddIcon />}
          >
            Update messaging service sid
          </Button>
        </Box>
      </FormProvider>
    </Box>
  );
}
export default UpdateMessagingServiceSidPage;
